<template lang="pug">
  .user-manage
    .container
      .row.justify-content-md-center
        .col-lg-3
          h1.text-center 支払情報登録
          p 支払い方法をお選びください
          form.needs-validation(@submit="exec(true)" ref="form" method="POST" novalidate)
            .form-group
              .form-check.pl-0
                input#payMethod1.form-check-input(type="radio" v-model="payMethod" name="payMethod" value="credit" check="checked")
                label.form-check-label.radio-label(for="payMethod1") カード
              .form-check.pl-0
                input#payMethod2.form-check-input(type="radio" v-model="payMethod" name="payMethod" value="docomo")
                label.form-check-label.radio-label(for="payMethod2") docomo
              .form-check.pl-0
              //-   input#payMethod3.form-check-input(type="radio" v-model="payMethod" name="payMethod" value="auone")
              //-   label.form-check-label.radio-label(for="payMethod3") au
              .form-check.pl-0
                input#payMethod4.form-check-input(type="radio" v-model="payMethod" name="payMethod" value="softbank2")
                label.form-check-label.radio-label(for="payMethod4") SoftBank
            .form-group.text-center
              input.btn.btn-primary.col-md-12(type="submit" value="次へ" v-bind:disabled="isDisabled")
            input(type="hidden" name="pay_method" ref="payMethodHidden")
            input(type="hidden" name="merchant_id" value="74522")
            input(type="hidden" name="service_id" value="001")
            input(type="hidden" name="cust_code" ref="userId")
            input(type="hidden" name="order_id" ref="orderId")
            input(type="hidden" name="item_id" ref="itemId")
            input(type="hidden" name="item_name" ref="itemName")
            input(type="hidden" name="service_type" ref="serviceType")
            input(type="hidden" name="terminal_type" ref="terminalType")
            input(type="hidden" name="success_url" ref="successUrl")
            input(type="hidden" name="cancel_url" ref="cancelUrl")
            input(type="hidden" name="error_url" ref="errorUrl")
            input(type="hidden" name="pagecon_url" ref="pageconUrl")
            input(type="hidden" name="request_date" ref="requestDate")
            input(type="hidden" name="limit_second" value="600")
            input(type="hidden" name="sps_hashcode" ref="hashcode")
</template>

<script>
import { createHash } from 'crypto'
import axios from 'axios'

import {
  Config,
  CognitoIdentityCredentials
} from 'aws-sdk'
import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserAttribute
} from 'amazon-cognito-identity-js'

export default {
  data() {
    return {
      message: '',
      isDisabled: false,
      payMethod: 'credit',
    }
  },
  methods: {
    exec(isPay) {
      this.isDisabled = true

      this.messages = ''

      //日付取得
      let date = new Date () 

      let year = date.getFullYear() 	// 年
      let month = ('0' + (date.getMonth() + 1)).slice(-2)	// 月
      let day = ('0' + date.getDate()).slice(-2) //日
      let hour = ('0' + date.getHours()).slice(-2) 	// 時
      let minute = ('0' + date.getMinutes()).slice(-2)	// 分
      let second = ('0' + date.getSeconds()).slice(-2) 	// 秒

      this.$refs.requestDate.value = '' + year + month + day + hour + minute + second

      if(this.payMethod == 'credit') {
        this.$refs.orderId.value = ''
      }else if(this.payMethod == '') {
        this.message == err.message
        return
      }else {
        this.$refs.orderId.value = '' + year + month + day + hour + minute + second
      }

      this.$refs.payMethodHidden.value = this.payMethod
      this.$refs.userId.value = this.$user.userId

      //クレジット決済の場合
      if(this.payMethod == 'credit') {
        this.$refs.form.action = process.env.VUE_APP_PAY_CREDIT
        this.$refs.itemId.value = ''
        this.$refs.itemName.value = ''
        this.$refs.serviceType.value = ''
        this.$refs.terminalType.value = '0'
      }else {
        //キャリア決済の場合
        this.$refs.form.action = process.env.VUE_APP_PAY_CARRIER
        this.$refs.itemId.value = '001'
        this.$refs.itemName.value = ''
        this.$refs.serviceType.value = '0'
        this.$refs.terminalType.value = '0'
      }

      // ハッシュ値設定
      this.createCheckHash(this.$refs.form)

    },
    createCheckHash(e) {
      const hash = process.env.VUE_APP_PAY_HASH 
      let str = ''
      e.children.forEach(elm => {
        if (elm.type !== 'hidden') return
        str += elm.value
      })
      let shasum = createHash('sha1')
      shasum.update(str + hash)
      this.$refs.hashcode.value = shasum.digest('hex')
    },
    asyncDialog() {
      return new Promise((resolve) => {
        this.$bvModal.msgBoxOk('既に支払登録をされています', {
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'success',
          footerClass: 'p-2',
          centered: true
        })
        .then(value => {
          resolve(value)
          this.$router.push('/')
        })
      })
    },
  },
  mounted() {
    this.$refs.successUrl.value = location.origin + '/user/payment/add_complate'
    this.$refs.cancelUrl.value = location.origin + '/user/payment/add_input'
    this.$refs.errorUrl.value = location.origin + '/'
    this.$refs.pageconUrl.value = process.env.VUE_APP_API_GATEWAY + '/api-pay'

    //payMethodに値があるか
    const jsonUrl = process.env.VUE_APP_API_GATEWAY + '/api-user'
    axios.get(jsonUrl, {
      headers: {
        'Authorization': this.$user.idToken
      }
    })
    .then(async res => {
      if(res.data.payment != null && res.data.payment != 'free') {
        await this.asyncDialog()
      }
    })
    .catch(err => {
      console.log(err)
    })
  }
}
</script>